const btnPositive = text => text ? `<button class="btn consent-btn consent-btn-positive" aria-hidden="false">${text}</button>` : '';
const btnNegative = text => text ? `<button class="btn consent-btn consent-btn-negative" aria-hidden="false">${text}</button>` : '';
const btnGoBack = text => text ? `<button class="btn consent-btn consent-btn-back hidden" aria-hidden="true">${text}</button>` : '';
const caliBtnNegative = text => text ? `<button class="btn consent-btn consent-btn-confirm hidden" aria-hidden="true">${text}</button>` : '';

const btnClose = use => use ? `<button aria-label="Close" class="btn close-btn">&times;</button>` : '';

const bannerTemplate = function bannerTemplate({ text, btnYesText, btnNoText, useCloseBtn, btnBackText, btnConfirmOptOut }) {
  return `
    <section class="tracking-consent-banner">
    <p class="consent-message" id="consent-message">
    ${text}
    </p>
    <div class="tracking-consent-banner__btn-container">
    ${ btnPositive(btnYesText) }
    ${ btnNegative(btnNoText) }
    ${ btnGoBack(btnBackText) }
    ${ caliBtnNegative(btnConfirmOptOut) }
    </div>
    ${ btnClose(useCloseBtn) }
    </section>
  `;
};

export default bannerTemplate;
