import './polyfill';
import bannerTemplate from './banner-template';

const defaultConfig = {
  text: 'using cookies?',
  btnYesText: null,
  btnNoText: null,
  btnConfirmOptOut: null,
  btnBackText: null,
  cookieKey: 'tracking_consent',
  cookieSettings: ';path=/;max-age=31536000',
  bannerContainer: document.body,
  onConsentGiven: null, 
  caliConsentText: null
};

const getConfig = (cfg) => cfg ? Object.assign({}, defaultConfig, cfg || {}) : null;
const getCookieYesState = cfg => `${ cfg.cookieKey }=yes${ cfg.cookieSettings }`;
const getCookieNoState = cfg => `${ cfg.cookieKey }=no${ cfg.cookieSettings }`;

function onUserAccept(config) {
  document.cookie = getCookieYesState(config)
  hideTrackingConsentBanner();
  config.onConsentGiven();
  
}

function onUserRefuse(config) {
  document.cookie = getCookieNoState(config);
  hideTrackingConsentBanner();
}

function getTrackingCookie(config) {
  let cookie = document.cookie.split(';')
    .filter(item => item.trim().indexOf(config.cookieKey) === 0);
  return cookie.length ? cookie[0].trim() : null;
}

function registerConsentPanelListeners(config) {
  document.querySelector('.tracking-consent-banner').addEventListener('click', e => {
    if (e.target.classList.contains('consent-btn-positive')) {
      onUserAccept(config);
      // renderCaliConsent(config);
    } else if (e.target.classList.contains('consent-btn-negative')) {
      // onUserRefuse(config);
      renderCaliConsent(config);
    } else if (e.target.classList.contains('close-btn')) {
      hideTrackingConsentBanner();
    } else if (e.target.classList.contains('consent-btn-back')){
      // re-render original text
      goBack(config)
    } else if (e.target.classList.contains('consent-btn-confirm')){
      // set the cookie 
      onUserRefuse(config)
    }

  });
}

function showTrackingConsentBanner(config) {
  const tpl = document.createElement('template');
  tpl.innerHTML = bannerTemplate(config);
  config.bannerContainer.insertBefore(tpl.content, config.bannerContainer.firstChild);
  registerConsentPanelListeners(config);
}

function hideTrackingConsentBanner() {
  const banner = document.querySelector('.tracking-consent-banner');
  banner.parentNode.removeChild(banner);
}

function renderCaliConsent(config){
  document.querySelector('#consent-message').innerHTML = config.caliConsentText;
  hideGdprButtons();
  showCaliButtons();

}

function goBack(config){
  document.querySelector('#consent-message').innerHTML = config.text;
  showGdprButtons();
  hideCaliButtons();
  
}

//Visibility controllers
function hideGdprButtons(){
  document.querySelector('.consent-btn-positive').classList.add('hidden');
  document.querySelector('.consent-btn-positive').setAttribute("aria-hidden", "true");
  document.querySelector('.consent-btn-negative').classList.add('hidden');
  document.querySelector('.consent-btn-negative').setAttribute("aria-hidden", "true");

}
function showGdprButtons(){
  document.querySelector('.consent-btn-positive').classList.remove('hidden');
  document.querySelector('.consent-btn-positive').setAttribute("aria-hidden", "false");
  document.querySelector('.consent-btn-negative').classList.remove('hidden');
  document.querySelector('.consent-btn-negative').setAttribute("aria-hidden", "false");

}

function hideCaliButtons(){
  document.querySelector('.consent-btn-back').classList.add('hidden');
  document.querySelector('.consent-btn-back').setAttribute("aria-hidden", "true");
  document.querySelector('.consent-btn-confirm').classList.add('hidden');
  document.querySelector('.consent-btn-confirm').setAttribute("aria-hidden", "true");
}
function showCaliButtons(){
  document.querySelector('.consent-btn-back').classList.remove('hidden');
  document.querySelector('.consent-btn-back').setAttribute("aria-hidden", "false");
  document.querySelector('.consent-btn-confirm').classList.remove('hidden');
  document.querySelector('.consent-btn-confirm').setAttribute("aria-hidden", "false");
}

// function initAnalytics() {
//   window.dataLayer = window.dataLayer || [];
//   function gtag() {
//     window.dataLayer.push(arguments);
//   }
//   gtag('js', new Date());
//   gtag('config', 'UA-61002187-3');
// }

// function initGTM() {
//   /*eslint-disable */
//   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//   })(window,document,'script','dataLayer','GTM-TM54HGV');
//   /*eslint-enable */
// }

function initDoNotSellInformationUI(config) {
  const form = document.getElementById('do-not-sell-info-form');
  if (!form) return;
  const cb = form.querySelector('#do-not-sell-info-cb');
  const submitBtn = form.querySelector('#do-not-sell-info-submit');
  const validation = form.querySelector('#do-not-sell-info-validation');
  const confirmation = document.querySelector('#do-not-sell-info-confirmation');
  const continueBtn = confirmation.querySelector('#do-not-sell-info-continue');

  submitBtn.addEventListener('click', e => {
    e.preventDefault();
    validation.style.display = cb.checked ? 'none' : 'block';
    if(cb.checked) {
      document.cookie = getCookieNoState(config);
      form.style.display = 'none';
      confirmation.style.display = 'block';
    }
  });

  continueBtn.addEventListener('click', () => history.back());
}

function init(cfg) {
  const config = getConfig(cfg);

  if(!config) {
    return;
  }

  const cookie = getTrackingCookie(config);

  if (!cookie && typeof config.onConsentGiven === 'function') {
    showTrackingConsentBanner(config);
  }

  if (cookie === `${ config.cookieKey }=yes`) {
    config.onConsentGiven();
  }

  initDoNotSellInformationUI(config);
}

window.PFS = window.PFS || {};
window.PFS.components = window.PFS.components || {};
window.PFS.components.trackingConsentBanner = { init };

export default { init };
